<template>
  <div v-if="category" class="category-title">
    <h2 class="pt-2 profile-title">
      {{ title }}
    </h2>
    <div v-if="subtitle" v-html="subtitle"></div>
  </div>
</template>

<script>
export default {
  name: "ProfileTitle",
  props: {
    category: { type: Object, required: true }
  },
  computed: {
    title() {
      let c = this.selectedCategory ? this.selectedCategory : this.category;
      return this._.get(c, "metaData.category_info.TITLE", c.name);
    },
    subtitle() {
      return this._.get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    }
  }
};
</script>
