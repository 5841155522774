<template>
  <v-card
    color="primary lighten-1"
    elevation="0"
    class="pa-2 d-flex flex-column align-center  white--text"
  >
    <img class="pa-3 mb-2" src="/img/help.svg" alt="help" />
    <h3 class="text-center">
      <strong>{{ $t("leftColumnCards.support.title") }}</strong>
    </h3>
    <span class="description text-center mt-1 mb-4">{{
      $t("leftColumnCards.support.description")
    }}</span>
    <v-btn
      text
      depressed
      :to="{ name: 'Page', params: { pageName: 'servizio-clienti' } }"
      class="mb-3 primary--text white"
    >
      {{ $t("leftColumnCards.support.button") }}
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "SupportCard"
};
</script>
