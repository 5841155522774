<template>
  <div class="address-card-container">
    <v-card
      flat
      class="address"
      v-bind:class="[address.addressId == shippingAddressId ? 'selected' : '']"
    >
      <!-- blocco azioni superiori -->
      <v-card-actions class="justify-space-between">
        <!-- indirizzo di spedizione -->

        <v-btn
          v-if="address.addressId == shippingAddressId"
          elevation="0"
          text
          x-small
          class="pa-0"
          disabled
        >
          <v-icon small class="mr-1">$starfull</v-icon>
          <span class="delivery-address-select">{{
            $t("addresses.card.deliveryAddressYes")
          }}</span>
        </v-btn>
        <v-btn
          v-else-if="address.addressTypeId === 2"
          elevation="0"
          text
          x-small
          class="pa-0"
          @click.stop="setDeliveryAddress"
        >
          <v-icon small class="mr-1">$star</v-icon>
          <span class="delivery-address-select">{{
            $t("addresses.card.deliveryAddressNo")
          }}</span>
        </v-btn>

        <v-spacer />

        <v-btn
          v-if="address.addressTypeId === 2"
          class="has-delivery"
          text
          x-small
        >
          <!-- Indirizzo coperto dal servizio e attivo pe rla delivery -->
          {{ $t("addresses.card.hasDeliveryYes") }}
        </v-btn>
        <v-btn
          v-else-if="address.addressTypeId === 102 && address.zoneId > 0"
          class="has-delivery disabled"
          text
          x-small
          @click="sendAddressActivationRequest"
        >
          <!-- Indirizzo coperto dal servizio e ma non attivo per la delivery -->
          {{ $t("addresses.card.hasDeliveryNo") }}
        </v-btn>
        <v-btn
          v-else
          class="has-delivery disabled text-grey"
          text
          x-small
          disabled
        >
          <!-- Indirizzo  non coperto dal servizio e ma non attivo per la delivery -->
          {{ $t("addresses.card.noDelivery") }}
        </v-btn>
      </v-card-actions>

      <!-- TODO: nome azienda (prendo dallo user oppure da dove?)-->
      <v-card-title class="px-4 text-left mb-3">
        {{ address.addressName }}</v-card-title
      >
      <v-card-subtitle class="pb-0 px-4 text-left"
        >{{ $t("addresses.card.cardNumber") }}
        <!-- TODO: numero tessera (da dove?) -->
        <span class="value">{{ address.codInt }}</span>
      </v-card-subtitle>
      <!-- indirizzo -->
      <v-card-text class="pb-0 px-4 text-left">
        <div>
          {{ address.address1 }} {{ address.addressNumber }},
          {{ address.city }} {{ address.postalcode }} ({{ address.province }})
        </div>
      </v-card-text>

      <!-- campi di input -->
      <v-card-actions class="fields d-flex flex-column pa-0">
        <!-- telefono -->
        <v-row class="">
          <v-col cols="6" class="pa-0 pl-2 pr-1">
            <v-text-field
              ref="homephone"
              v-model="address.referencePhoneNumber"
              :error-messages="errors"
              type="tel"
              height="32"
              :placeholder="$t('addresses.card.phoneNumber')"
              :outlined="editingPhone"
              :rules="isHomePhoneRules"
              dense
              :clearable="editingPhone"
              :readonly="!editingPhone"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pa-0 pr-1 d-flex justify-center">
            <span
              v-if="!editingPhone"
              :class="address.referencePhoneNumber ? 'edit' : 'insert'"
              @click="editField('editingPhone')"
              >{{
                address.referencePhoneNumber
                  ? $t("addresses.card.editField")
                  : $t("addresses.card.insertField")
              }}</span
            >
            <div v-else class="d-flex flex-row">
              <v-btn
                class="ml-0 mr-1 white--text"
                color="primary"
                elevation="0"
                dense
                :disabled="!isValidInput('homephone')"
                @click="
                  confirmEdit(address.referencePhoneNumber, 'editingPhone')
                "
                >{{ $t("addresses.card.confirmField") }}</v-btn
              >
              <v-btn
                class="ml-0"
                elevation="0"
                dense
                @click="
                  resetField(
                    'homephone',
                    'referencePhoneNumber',
                    'editingPhone'
                  )
                "
                >{{ $t("addresses.card.resetField") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>

        <!-- cellulare -->
        <v-row class="">
          <v-col cols="6" class="pa-0 pl-2 pr-1">
            <v-text-field
              ref="mobilephone"
              v-model="address.referenceMobileNumber"
              :error-messages="errors"
              type="tel"
              height="32"
              :rules="isMobilePhoneRules"
              :placeholder="$t('addresses.card.mobileNumber')"
              :outlined="editingMobile"
              dense
              :clearable="editingMobile"
              :readonly="!editingMobile"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pa-0 pr-1 d-flex justify-center">
            <span
              v-if="!editingMobile"
              :class="address.referenceMobileNumber ? 'edit' : 'insert'"
              @click="editField('editingMobile')"
              >{{
                address.referenceMobileNumber
                  ? $t("addresses.card.editField")
                  : $t("addresses.card.insertField")
              }}</span
            >
            <div v-else class="d-flex flex-row">
              <v-btn
                class="ml-0 mr-1 white--text"
                color="primary"
                elevation="0"
                dense
                :disabled="!isValidInput('mobilephone')"
                @click="
                  confirmEdit(address.referenceMobileNumber, 'editingMobile')
                "
                >{{ $t("addresses.card.confirmField") }}</v-btn
              >
              <v-btn
                class="ml-0"
                elevation="0"
                dense
                @click="
                  resetField(
                    'mobilephone',
                    'referenceMobileNumber',
                    'editingMobile'
                  )
                "
                >{{ $t("addresses.card.resetField") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>

        <!-- email -->
        <v-row class="justify-space-between mb-2">
          <v-col cols="6" class="pa-0 pl-2 pr-1">
            <v-text-field
              ref="email"
              v-model="address.referenceEMail"
              :error-messages="errors"
              height="32"
              :placeholder="$t('addresses.card.email')"
              :rules="emailRules"
              :outlined="editingEmail"
              dense
              :clearable="editingEmail"
              :readonly="!editingEmail"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pa-0 pr-1 d-flex justify-center">
            <span
              v-if="!editingEmail"
              :class="address.referenceEMail ? 'edit' : 'insert'"
              @click="editField('editingEmail')"
              >{{
                address.referenceEMail
                  ? $t("addresses.card.editField")
                  : $t("addresses.card.insertField")
              }}</span
            >
            <div v-else class="d-flex flex-row">
              <v-btn
                class="ml-0 mr-1 white--text"
                color="primary"
                elevation="0"
                dense
                :disabled="!isValidInput('email')"
                @click="confirmEdit(address.referenceEMail, 'editingEmail')"
                >{{ $t("addresses.card.confirmField") }}</v-btn
              >
              <v-btn
                class="ml-0"
                elevation="0"
                dense
                @click="resetField('email', 'referenceEMail', 'editingEmail')"
                >{{ $t("addresses.card.resetField") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-btn
          v-if="address.addressTypeId === 102 && address.zoneId > 0"
          color="primary"
          class="mb-3"
          @click="sendAddressActivationRequest"
        >
          Richiedi attivazione
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import CustomMarketSuiteService from "@/service/marketsuiteCustomService";
import { mapGetters, mapState } from "vuex";
import {
  requiredValue,
  isEmail,
  isMobilePhone,
  isHomePhone
} from "@/validator/validationRules";
export default {
  name: "AddressCard",
  props: ["address", "type", "selectionEnabled", "user"],
  data() {
    return {
      errors: [],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      isHomePhoneRules: [isHomePhone()],
      isMobilePhoneRules: [isMobilePhone()],
      editingPhone: false,
      editingMobile: false,
      editingEmail: false
    };
  },
  mounted() {
    // necessario forzare l'initialValue all'inizio e per i reset successivi al primo update
    this.setInitialValues();
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({ shippingAddressId: "cart/getAddressId" })
  },
  methods: {
    async sendAddressActivationRequest() {
      let res = await this.$dialog.confirm({
        text: "Vuoi richiedere che l'indirizzo venga attivato per la consegna?"
      });
      if (res) CustomMarketSuiteService.requestAddressActivation(this.address);
    },
    isValidInput(field) {
      return this.$refs[field].valid;
    },
    resetField(field, model, flag) {
      this.address[model] = this.$refs[field].initialValue;
      this[flag] = false;
    },
    setInitialValues() {
      this.$refs.homephone.initialValue = this.address.referencePhoneNumber;
      this.$refs.mobilephone.initialValue = this.address.referenceMobileNumber;
      this.$refs.email.initialValue = this.address.referenceEMail;
    },
    editField(fieldToEdit) {
      this[fieldToEdit] = true;
    },
    confirmEdit(value, fieldToEdit) {
      this[fieldToEdit] = false;
      this.$emit("updateaddress", this.address);
    },
    setDeliveryAddress() {
      this.$emit("setdelivery", this.address);
    }
    // removeAddress(address) {
    //   var _this = this;
    //   this.$dialog
    //     .confirm("Sicuro di Eliminare l'Indirizzo ?")
    //     // eslint-disable-next-line no-unused-vars
    //     .then(function(dialog) {
    //       _this.$emit("removeAddress", address);
    //       dialog.close();
    //     })
    //     .catch(function() {});
    // },
    // editAddress(address) {
    //   this.$router.push({
    //     path: "/edit-addresses/",
    //     name: "/edit-addresses/",
    //     params: {
    //       address: address,
    //       newAddress: false
    //     }
    //   });
    // }
  },
  watch: {
    address(val) {
      this.address = val;
      this.setInitialValues();
    }
  }
};
</script>
